export class MonthlyStatement {
  constructor(
    public userUid?: string,
    public year?: string,
    public month?: string,
    public declared?: boolean,
    public emittedLength?: number,
    public receivedLength?: number,
    public receivedSubtotal?: number,
    public receivedTaxTotal?: number,
    public emittedSubtotal?: number,
    public emittedTaxTotal?: number,
    public utility?: number,
    public taxToPay?: number,
    public isr?: number
  ) { }
}