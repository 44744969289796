import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(public db: AngularFirestore) { }

  getReceivedInvoicesList(userUid: string, monthId: string) {
    return this.db.collection('invoices', ref => ref.where('uid', '==', userUid)
                                                    .where('type', '==', 'received')
                                                    .where('monthId', '==', monthId)).snapshotChanges();
  }
  getEmittedInvoicesList(userUid: string, monthId: string) {
    return this.db.collection('invoices', ref => ref.where('uid', '==', userUid)
                                                    .where('type', '==', 'emitted')
                                                    .where('monthId', '==', monthId)).snapshotChanges();
  }

  deleteInvoice(invoiceKey) {
    return this.db.collection('invoices').doc(invoiceKey).delete();
  }

  createInvoice(value) {
    return this.db.collection('invoices').add({
      uid: value.uid,
      monthId: value.monthId,
      type: value.type,
      comprobante: {
        descuento: value.comprobante.descuento,
        fecha: value.comprobante.fecha,
        folio: value.comprobante.folio,
        formaPago: value.comprobante.formaPago,
        lugarExpedicion: value.comprobante.lugarExpedicion,
        tipoCambio: value.comprobante.tipoCambio,
        subTotal: value.comprobante.subTotal,
        tipoDeComprobante: value.comprobante.tipoDeComprobante,
        total: value.comprobante.total,
        version: value.comprobante.version,
        metodoPago: value.comprobante.metodoPago,
        moneda: value.comprobante.moneda,
        noCertificado: value.comprobante.noCertificado,
        emisor: {
          nombre: value.emisor.nombre,
          regimenFiscal: value.emisor.regimenFiscal,
          rfc: value.emisor.rfc,
        },
        receptor: {
          nombre: value.receptor.nombre,
          rfc: value.receptor.rfc,
          usoCfdi: value.receptor.usoCfdi,
        },
        conceptos: value.conceptos,
        impuestos: {
          totalImpuestosTrasladados: value.impuestos.totalImpuestosTrasladados,
          traslados: value.traslados,
        },
      }
    })
  }

}
