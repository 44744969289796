import { Component, OnInit } from '@angular/core';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass']
})
export class SidebarComponent implements OnInit {
  faReceipt = faReceipt;
  constructor() { }

  ngOnInit() {
  }

}
