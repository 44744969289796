import { Component, OnInit, HostListener } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import {
  faArrowLeft,
  faTimes,
  faFileUpload,
  faCloudUploadAlt
} from "@fortawesome/free-solid-svg-icons";
import { InvoiceService } from "../../core/services/invoice.service";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";

@Component({
  selector: "app-received-xml-upload",
  templateUrl: "./received-xml-upload.component.html",
  styleUrls: ["./received-xml-upload.component.sass"]
})
export class ReceivedXmlUploadComponent implements OnInit {
  month_id: string;
  faArrowLeft = faArrowLeft;
  faTimes = faTimes;
  faFileUpload = faFileUpload;
  faCloudUploadAlt = faCloudUploadAlt;
  invoice: any;
  comprobante: any;
  emisor: any;
  receptor: any;
  conceptox: any;
  concepto: any;
  impuestos: any;
  trasladox: any;
  traslado: any;
  complemento: any;
  dropzoneActive: boolean = false;
  uid: string;
  monthId: string;

  constructor(
    private _location: Location,
    private router: Router,
    private invoiceService: InvoiceService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.monthId = this.route.snapshot.paramMap.get("month_id");
    this.uid = this.route.snapshot.paramMap.get("uid");
  }

  previousState() {
    this._location.back();
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.previousState();
  }

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  handleDrop(fileList: FileList) {
    let filesIndex = _.range(fileList.length);
    _.each(filesIndex, idx => {
      let file: File = fileList[idx];
      let reader = new FileReader();
      var parser;
      reader.onload = () => {
        var text = reader.result;
        parser = new DOMParser();
        var i, xmlDoc;
        xmlDoc = parser.parseFromString(text, "text/xml");
        this.comprobante = xmlDoc.getElementsByTagName("cfdi:Comprobante");
        for (i = 0; i < this.comprobante.length; i++) {
          if (this.comprobante[i].getAttribute("version") === "3.2") {
            this.readVersion_3_2(xmlDoc);
          } else {
            this.readVersion_3_3(xmlDoc);
          }
        }
      };
      reader.readAsText(file);
    });
  }

  fileChange(event: any) {
    let fileList: FileList = event.target.files;
    let filesIndex = _.range(fileList.length);
    _.each(filesIndex, idx => {
      let file: File = fileList[idx];
      let reader = new FileReader();
      var parser;
      reader.onload = () => {
        var text = reader.result;
        parser = new DOMParser();
        var i, xmlDoc;
        xmlDoc = parser.parseFromString(text, "text/xml");
        console.log(xmlDoc);
        this.comprobante = xmlDoc.getElementsByTagName("cfdi:Comprobante");
        for (i = 0; i < this.comprobante.length; i++) {
          if (this.comprobante[i].getAttribute("version") === "3.2") {
            this.readVersion_3_2(xmlDoc);
          } else {
            this.readVersion_3_3(xmlDoc);
          }
        }
      };
      reader.readAsText(file);
    });
  }

  readVersion_3_2(xmlDoc: any) {
    this.invoice = {
      uid: this.uid,
      monthId: this.monthId,
      type: "received"
    };

    this.comprobante = xmlDoc.getElementsByTagName("cfdi:Comprobante");
    let comprobantesIndex = _.range(this.comprobante.length);
    _.each(comprobantesIndex, idx => {
      this.invoice.comprobante = {
        descuento: this.comprobante[idx].getAttribute("descuento"),
        fecha: this.comprobante[idx].getAttribute("fecha"),
        folio: this.comprobante[idx].getAttribute("folio"),
        formaPago: this.comprobante[idx].getAttribute("formaDePago"),
        lugarExpedicion: this.comprobante[idx].getAttribute("LugarExpedicion"),
        tipoCambio: this.comprobante[idx].getAttribute("TipoCambio"),
        subTotal: this.comprobante[idx].getAttribute("subTotal"),
        tipoDeComprobante: this.comprobante[idx].getAttribute(
          "tipoDeComprobante"
        ),
        total: this.comprobante[idx].getAttribute("total"),
        version: this.comprobante[idx].getAttribute("version"),
        metodoPago: this.comprobante[idx].getAttribute("metodoDePago"),
        moneda: this.comprobante[idx].getAttribute("Moneda"),
        noCertificado: this.comprobante[idx].getAttribute("noCertificado")
      };

      this.emisor = xmlDoc.getElementsByTagName("cfdi:Emisor");
      let emisorIndex = _.range(this.emisor.length);
      _.each(emisorIndex, idx => {
        this.invoice.emisor = {
          nombre: this.emisor[idx].getAttribute("nombre"),
          rfc: this.emisor[idx].getAttribute("rfc"),
          regimenFiscal: this.emisor[idx].getAttribute("Regimen")
        };
      });

      this.receptor = xmlDoc.getElementsByTagName("cfdi:Receptor");
      let receptorIndex = _.range(this.receptor.length);
      _.each(receptorIndex, idx => {
        this.invoice.receptor = {
          nombre: this.receptor[idx].getAttribute("nombre"),
          rfc: this.receptor[idx].getAttribute("rfc"),
          usoCfdi: this.receptor[idx].getAttribute("usoCfdi")
        };
      });

      this.conceptox = xmlDoc.getElementsByTagName("cfdi:Concepto");
      let conceptoIndex = _.range(this.conceptox.length);
      this.invoice.conceptos = [];
      _.each(conceptoIndex, idx => {
        this.concepto = {
          cantidad: this.conceptox[idx].getAttribute("cantidad"),
          unidad: this.conceptox[idx].getAttribute("unidad"),
          noIdentificacion: this.conceptox[idx].getAttribute(
            "noIdentificacion"
          ),
          descripcion: this.conceptox[idx].getAttribute("descripcion"),
          importe: this.conceptox[idx].getAttribute("importe"),
          valorUnitatio: this.conceptox[idx].getAttribute("valorUnitario")
        };
        this.invoice.conceptos.push(this.concepto);
      });

      this.impuestos = xmlDoc.getElementsByTagName("cfdi:Impuestos");
      let impuestosIndex = _.range(this.impuestos.length);
      if (this.impuestos.length > 0) {
        _.each(impuestosIndex, idx => {
          if (this.impuestos[idx].getAttribute("totalImpuestosTrasladados")) {
            this.invoice.impuestos = {
              totalImpuestosTrasladados: this.impuestos[idx].getAttribute(
                "totalImpuestosTrasladados"
              )
            };
          } else {
            this.invoice.impuestos = {
              totalImpuestosTrasladados: 0
            };
          }
        });
      } else {
        this.invoice.impuestos = {
          totalImpuestosTrasladados: 0
        };
      }

      this.trasladox = xmlDoc.getElementsByTagName("cfdi:Traslado");
      let trasladoIndex = _.range(this.trasladox.length);
      this.invoice.traslados = [];
      _.each(trasladoIndex, idx => {
        this.traslado = {
          importe: this.trasladox[idx].getAttribute("importe"),
          impuesto: this.trasladox[idx].getAttribute("impuesto"),
          tasaOCuota: this.trasladox[idx].getAttribute("tasa"),
          tipoFactor: this.trasladox[idx].getAttribute("TipoFactor")
        };
        this.invoice.traslados.push(this.traslado);
      });
      if (this.invoice.impuestos.totalImpuestosTrasladados === 0) {
        let sum = 0;
        this.invoice.traslados.forEach(traslado => {
          sum += traslado.importe;
        });
        this.invoice.impuestos.totalImpuestosTrasladados = parseInt(
          sum.toString(),
          10
        );
      }
      this.invoiceService.createInvoice(this.invoice).then(res => {
        this.router.navigate(["/months/" + this.uid + "/" + this.monthId]);
      });
    });
  }

  readVersion_3_3(xmlDoc: any) {
    this.invoice = {
      uid: this.uid,
      monthId: this.monthId,
      type: "received"
    };

    this.comprobante = xmlDoc.getElementsByTagName("cfdi:Comprobante");
    let comprobantesIndex = _.range(this.comprobante.length);
    _.each(comprobantesIndex, idx => {
      this.invoice.comprobante = {
        descuento: this.comprobante[idx].getAttribute("Descuento"),
        fecha: this.comprobante[idx].getAttribute("Fecha"),
        folio: this.comprobante[idx].getAttribute("Folio"),
        formaPago: this.comprobante[idx].getAttribute("FormaPago"),
        lugarExpedicion: this.comprobante[idx].getAttribute("LugarExpedicion"),
        tipoCambio: this.comprobante[idx].getAttribute("TipoCambio"),
        subTotal: this.comprobante[idx].getAttribute("SubTotal"),
        tipoDeComprobante: this.comprobante[idx].getAttribute(
          "TipoDeComprobante"
        ),
        total: this.comprobante[idx].getAttribute("Total"),
        version: this.comprobante[idx].getAttribute("Version"),
        metodoPago: this.comprobante[idx].getAttribute("MetodoPago"),
        moneda: this.comprobante[idx].getAttribute("Moneda"),
        noCertificado: this.comprobante[idx].getAttribute("NoCertificado")
      };

      this.emisor = xmlDoc.getElementsByTagName("cfdi:Emisor");
      let emisorIndex = _.range(this.emisor.length);
      _.each(emisorIndex, idx => {
        this.invoice.emisor = {
          nombre: this.emisor[idx].getAttribute("Nombre"),
          rfc: this.emisor[idx].getAttribute("Rfc"),
          regimenFiscal: this.emisor[idx].getAttribute("RegimenFiscal")
        };
      });

      this.receptor = xmlDoc.getElementsByTagName("cfdi:Receptor");
      let receptorIndex = _.range(this.receptor.length);
      _.each(receptorIndex, idx => {
        this.invoice.receptor = {
          nombre: this.receptor[idx].getAttribute("Nombre"),
          rfc: this.receptor[idx].getAttribute("Rfc"),
          usoCfdi: this.receptor[idx].getAttribute("UsoCFDI")
        };
      });

      this.conceptox = xmlDoc.getElementsByTagName("cfdi:Concepto");
      let conceptoIndex = _.range(this.conceptox.length);
      this.invoice.conceptos = [];
      _.each(conceptoIndex, idx => {
        this.concepto = {
          cantidad: this.conceptox[idx].getAttribute("Cantidad"),
          claveProdServ: this.conceptox[idx].getAttribute("ClaveProdServ"),
          claveUnidad: this.conceptox[idx].getAttribute("ClaveUnidad"),
          descripcion: this.conceptox[idx].getAttribute("Descripcion"),
          descuento: this.conceptox[idx].getAttribute("Descuento"),
          importe: this.conceptox[idx].getAttribute("Importe"),
          valorUnitatio: this.conceptox[idx].getAttribute("ValorUnitario")
        };
        this.invoice.conceptos.push(this.concepto);
      });

      this.impuestos = xmlDoc.getElementsByTagName("cfdi:Impuestos");
      let impuestosIndex = _.range(this.impuestos.length);
      if (this.impuestos.length > 0) {
        _.each(impuestosIndex, idx => {
          this.invoice.impuestos = {
            totalImpuestosTrasladados: this.impuestos[idx].getAttribute(
              "TotalImpuestosTrasladados"
            )
          };
        });
      } else {
        this.invoice.impuestos = {
          totalImpuestosTrasladados: 0
        };
      }

      this.trasladox = xmlDoc.getElementsByTagName("cfdi:Traslado");
      let trasladoIndex = _.range(this.trasladox.length);
      this.invoice.traslados = [];
      _.each(trasladoIndex, idx => {
        this.traslado = {
          importe: this.trasladox[idx].getAttribute("Importe"),
          impuesto: this.trasladox[idx].getAttribute("Impuesto"),
          tasaOCuota: this.trasladox[idx].getAttribute("TasaOCuota"),
          tipoFactor: this.trasladox[idx].getAttribute("TipoFactor")
        };
        this.invoice.traslados.push(this.traslado);
      });
      this.invoiceService.createInvoice(this.invoice).then(res => {
        this.router.navigate(["/months/" + this.uid + "/" + this.monthId]);
      });
    });
  }
}
