import { Component, OnInit, HostListener } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Location } from "@angular/common";
import { MonthlyStatementService } from "../../core/services/monthly-statement.service";
import { MonthlyStatement } from "../../shared/models/monthly_statement";
import { AuthService } from "../../core/authentication/auth.service";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
import * as _ from "lodash";

@Component({
  selector: "app-month-new",
  templateUrl: "./month-new.component.html",
  styleUrls: ["./month-new.component.sass"]
})
export class MonthNewComponent implements OnInit {
  faArrowLeft = faArrowLeft;
  faTimes = faTimes;
  startYear: any = moment().subtract(8, "year");
  currentYear: any = moment();
  selectedYear: string;
  range = moment.range(this.startYear, this.currentYear);
  years = Array.from(this.range.by("year")).map(m => m.format("YYYY"));
  dropzoneActive: boolean = false;
  monthly_statement = new MonthlyStatement(
    "",
    this.selectedYear,
    "Enero",
    false,
    null,
    null,
    null,
    null,
    null,
    null
  );
  monthId: string;
  months: any = [
    { name: "Enero" },
    { name: "Febrero" },
    { name: "Marzo" },
    { name: "Abril" },
    { name: "Mayo" },
    { name: "Junio" },
    { name: "Julio" },
    { name: "Agosto" },
    { name: "Septiembre" },
    { name: "Octubre" },
    { name: "Noviembre" },
    { name: "Diciembre" }
  ];

  constructor(
    private _location: Location,
    private router: Router,
    private monthService: MonthlyStatementService,
    public auth: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.selectedYear = this.route.snapshot.paramMap.get("selectedYear");
  }

  previousState() {
    this._location.back();
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.previousState();
  }

  onSubmit(userUid: string) {
    this.monthly_statement.userUid = userUid;
    this.monthly_statement.year = this.selectedYear;
    this.monthService
      .createMonthlyStatement(this.monthly_statement)
      .then(res => {
        this.router.navigate([
          "/months/choose-xml-type/" + userUid + "/" + res.id
        ]);
      });
  }
}
