import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { DeclarationPreviewComponent } from "./declaration-preview/declaration-preview.component";
import { DeclarationReceiptUploadComponent } from "./declaration-receipt-upload/declaration-receipt-upload.component";
import { PublicComponent } from "./public/public.component";
import { MonthListComponent } from "./month/month-list/month-list.component";
import { MonthNewComponent } from "./month/month-new/month-new.component";
import { MonthShowComponent } from "./month/month-show/month-show.component";
import { ChooseXmlTypeComponent } from "./xml-upload/choose-xml-type/choose-xml-type.component";
import { ReceivedXmlUploadComponent } from "./xml-upload/received-xml-upload/received-xml-upload.component";
import { EmittedXmlUploadComponent } from "./xml-upload/emitted-xml-upload/emitted-xml-upload.component";

import { AuthGuard } from "./core/authentication/auth.guard";

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent,
    pathMatch: "full"
  },
  { path: "home", component: HomeComponent },
  {
    path: "overview/:selectedYear",
    component: MonthListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "months/:uid/:month_id",
    component: MonthShowComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "months/choose-xml-type/:uid/:month_id",
    component: ChooseXmlTypeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "months/received-xml-upload/:uid/:month_id",
    component: ReceivedXmlUploadComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "months/emitted-xml-upload/:uid/:month_id",
    component: EmittedXmlUploadComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ":selectedYear/months/new",
    component: MonthNewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "declaration/preview/:declaration_id",
    component: DeclarationPreviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "declaration/receipt/upload",
    component: DeclarationReceiptUploadComponent,
    canActivate: [AuthGuard]
  },
  { path: "**", redirectTo: "home" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
