import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { faArrowLeft, faTimes, faDownload, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { MonthlyStatementService } from '../core/services/monthly-statement.service';
import { AuthService } from '../core/authentication/auth.service';

@Component({
  selector: 'app-declaration-preview',
  templateUrl: './declaration-preview.component.html',
  styleUrls: ['./declaration-preview.component.sass']
})
export class DeclarationPreviewComponent implements OnInit {
  faArrowLeft = faArrowLeft;
  faTimes = faTimes;
  faDownload = faDownload;
  faCloudDownloadAlt = faCloudDownloadAlt;
  data: any;
  declaringStatus: boolean = false;

  @Output() messageEvent = new EventEmitter<boolean>();

  @Input() declarationResult: Array<any>;
  @Input() selectedYear: string;
  includedMonths: Array<any> = [];
  utility: number = 0;
  taxToPay: number = 0;
  emittedSubtotal: number = 0;
  emittedTax: number = 0;
  emittedTotal: number = 0;
  receivedSubtotal: number = 0;
  receivedTax: number = 0;
  receivedTotal: number = 0;
  currentUserUid: string;
  items: Array<any>;
  agreeCheckbox = false;


  constructor(
    public auth: AuthService,
    private monthService: MonthlyStatementService,
  ) { }

  ngOnInit() {
    console.log(this.declarationResult);
    this.auth.user.subscribe(user => {
      if (user)
        this.currentUserUid = user.uid;
      this.getData(this.currentUserUid);
    })
  }

  getData(userId: string) {
    this.monthService.getMonthsByUserId(userId)
      .subscribe((res) => {
        this.items = res;
        this.filterMontsById(this.items);
      })
  }

  filterMontsById(months: Array<any>) {
    this.utility = 0;
    this.taxToPay = 0;
    this.emittedSubtotal = 0;
    this.emittedTax = 0;
    this.emittedTotal = 0;
    this.receivedSubtotal = 0;
    this.receivedTax = 0;
    this.receivedTotal = 0;
    for (let i = 0; i < months.length; i++) {
      for (let a = 0; a < this.declarationResult.length; a++) {
        if (months[i].payload.doc.id == this.declarationResult[a]) {
          this.emittedSubtotal += parseFloat(months[i].payload.doc.data().emittedSubtotal);
          this.emittedTax += parseFloat(months[i].payload.doc.data().emittedTaxTotal);
          this.receivedSubtotal += parseFloat(months[i].payload.doc.data().receivedSubtotal);
          this.receivedTax += parseFloat(months[i].payload.doc.data().receivedTaxTotal);
          this.includedMonths.push(months[i].payload.doc.data().month);
        }
      }
    }
    this.utility = this.emittedSubtotal - this.receivedSubtotal;
    this.taxToPay = this.emittedTax - this.receivedTax;
  }

  declareMonths() {
    for (let a = 0; a < this.declarationResult.length; a++) {
      this.data = {
        declared: true
      }
      this.monthService.updateMonthlyStatement(this.declarationResult[a], this.data)
    }
    this.sendMessage();
  }

  sendMessage() {
    this.messageEvent.emit(this.declaringStatus);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.sendMessage();
  }

  previousState() {
    this.sendMessage();
  }

}
