import { Component, OnInit } from "@angular/core";
import { AuthService } from "../core/authentication/auth.service";
import { Router } from "@angular/router";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.sass"]
})
export class HomeComponent implements OnInit {
  startYear: any = moment().subtract(8, "year");
  currentYear: any = moment();
  selectedYear: string = moment().format("YYYY");
  range = moment.range(this.startYear, this.currentYear);
  years = Array.from(this.range.by("year")).map(m => m.format("YYYY"));
  constructor(public auth: AuthService, private router: Router) {}

  ngOnInit() {}

  getByYear() {
    this.router.navigate(["/overview/" + this.selectedYear]);
  }
}
