import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

import { HomeComponent } from './home/home.component';
import { DeclarationPreviewComponent } from './declaration-preview/declaration-preview.component';
import { DeclarationReceiptUploadComponent } from './declaration-receipt-upload/declaration-receipt-upload.component';
import { PublicComponent } from './public/public.component';
import { SidebarComponent } from './ui/sidebar/sidebar.component';
import { TopbarComponent } from './ui/topbar/topbar.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MonthListComponent } from './month/month-list/month-list.component';
import { MonthNewComponent } from './month/month-new/month-new.component';
import { MonthShowComponent } from './month/month-show/month-show.component';
import { FileDropDirective } from './shared/directives/file-drop.directive';
import { ClickStopPropagation } from './shared/directives/stop-propagation.directive';
import { CoreModule } from './core/core.module';
import { ChooseXmlTypeComponent } from './xml-upload/choose-xml-type/choose-xml-type.component';
import { ReceivedXmlUploadComponent } from './xml-upload/received-xml-upload/received-xml-upload.component';
import { EmittedXmlUploadComponent } from './xml-upload/emitted-xml-upload/emitted-xml-upload.component'

import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DeclarationPreviewComponent,
    DeclarationReceiptUploadComponent,
    PublicComponent,
    SidebarComponent,
    TopbarComponent,
    MonthListComponent,
    MonthNewComponent,
    MonthShowComponent,
    FileDropDirective,
    ClickStopPropagation,
    ReceivedXmlUploadComponent,
    EmittedXmlUploadComponent,
    ChooseXmlTypeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase, 'organize-ant'),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    FontAwesomeModule,
    FormsModule,
    CoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
