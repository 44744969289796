import { Component, OnInit, HostListener } from '@angular/core';
import { faArrowLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from "@angular/router";
import { InvoiceService } from '../../core/services/invoice.service';
import { MonthlyStatementService } from '../../core/services/monthly-statement.service';
import { AuthService } from '../../core/authentication/auth.service';
import * as _ from "lodash";

@Component({
  selector: 'app-month-show',
  templateUrl: './month-show.component.html',
  styleUrls: ['./month-show.component.sass']
})
export class MonthShowComponent implements OnInit {
  faArrowLeft = faArrowLeft;
  faTrashAlt = faTrashAlt;
  selectedYear: any = "2019";
  selectedMonth: string = "Enero";
  receivedInvoices: Array<any>;
  emittedInvoices: Array<any>;
  currentUserUid: string;
  monthId: string;
  tabActive: boolean = true;
  month: any;

  emittedLength = 0;
  receivedLength = 0;
  receivedSubtotal = 0;
  receivedTaxTotal = 0;
  emittedSubtotal = 0;
  emittedTaxTotal = 0;
  utility = 0;
  taxToPay = 0;
  isr = 0;
  data: any;

  constructor(
    private _location: Location,
    public auth: AuthService,
    private router: Router,
    private invoiceService: InvoiceService,
    private monthService: MonthlyStatementService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.monthId = this.route.snapshot.paramMap.get("month_id");
    this.getMonth(this.monthId);
    this.auth.user.subscribe(user => {
      if (user)
        this.currentUserUid = user.uid;
        this.getReceivedInvoices(this.currentUserUid, this.monthId);
        this.getEmittedInvoices(this.currentUserUid, this.monthId);
    })
    setTimeout(() => { this.calculate() }, 1000)
  }


  previousState() {
    this._location.back();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.previousState()
  }

  getMonth(monthId: string) {
    this.monthService.getMonth(monthId)
      .subscribe(
        data => {
          this.month = data.payload.data();
        }
      );
  }


  getReceivedInvoices(userId: string, monthId: string) {
    this.invoiceService.getReceivedInvoicesList(userId, monthId)
      .subscribe((res) => {
        this.receivedInvoices = res;
      })
  }

  getEmittedInvoices(userId: string, monthId: string) {
    this.invoiceService.getEmittedInvoicesList(userId, monthId)
      .subscribe((res) => {
        this.emittedInvoices = res;
      })
  }

  goToXmlType(xmlType: string) {
    this.router.navigate(['/months/' + xmlType + '/' + this.currentUserUid + '/' + this.monthId]);
  }

  deleteInvoice(invoiceKey: string) {
    this.invoiceService.deleteInvoice(invoiceKey)
      .then(
        res => {
          this.calculate();
        },
        err => {
          console.log(err);
        }
      )
  }

  calculate() {
    this.emittedLength = 0;
    this.receivedLength = 0;
    this.receivedSubtotal = 0;
    this.receivedTaxTotal = 0;
    this.emittedSubtotal = 0;
    this.emittedTaxTotal = 0;
    this.utility = 0;
    this.taxToPay = 0;
    this.isr = 0;
    let receivedIndex = _.range(this.receivedInvoices.length)
    let emittedIndex = _.range(this.emittedInvoices.length)
    _.each(receivedIndex, (idx) => {
      this.receivedTaxTotal += parseFloat(this.receivedInvoices[idx].payload.doc.data().comprobante.impuestos.totalImpuestosTrasladados);
      this.receivedSubtotal += parseFloat(this.receivedInvoices[idx].payload.doc.data().comprobante.subTotal);
    })
    _.each(emittedIndex, (idx) => {
      this.emittedTaxTotal += parseFloat(this.emittedInvoices[idx].payload.doc.data().comprobante.impuestos.totalImpuestosTrasladados);
      this.emittedSubtotal += parseFloat(this.emittedInvoices[idx].payload.doc.data().comprobante.subTotal);
    })
    this.emittedLength = this.emittedInvoices.length;
    this.receivedLength = this.receivedInvoices.length;
    this.utility = this.emittedSubtotal - this.receivedSubtotal;
    this.taxToPay = this.emittedTaxTotal - this.receivedTaxTotal;
    this.data = {
      emittedLength: this.emittedInvoices.length,
      emittedSubtotal: this.emittedSubtotal,
      emittedTaxTotal: this.emittedTaxTotal,
      receivedLength: this.receivedInvoices.length,
      receivedSubtotal: this.receivedSubtotal,
      receivedTaxTotal: this.receivedTaxTotal,
      utility: this.emittedSubtotal - this.receivedSubtotal,
      taxToPay: this.emittedTaxTotal - this.receivedTaxTotal
    }
    this.updateMonth(this.monthId, this.data);
  }

  updateMonth(monthId: string, data: any) {
    this.monthService.updateMonthlyStatement(monthId, data)
  }

}
