import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root"
})
export class MonthlyStatementService {
  constructor(public db: AngularFirestore) {}

  getMonth(monthKey) {
    return this.db
      .collection("monthly_statements")
      .doc(monthKey)
      .snapshotChanges();
  }

  createMonthlyStatement(value) {
    return this.db.collection("monthly_statements").add({
      month: value.month,
      userUid: value.userUid,
      year: value.year,
      declared: value.declared,
      emittedLength: 0,
      receivedLength: 0,
      receivedSubtotal: 0,
      receivedTaxTotal: 0,
      emittedSubtotal: 0,
      emittedTaxTotal: 0,
      utility: 0,
      taxToPay: 0,
      isr: 0
    });
  }

  deleteMonthlyStatement(monthKey: string) {
    return this.db
      .collection("monthly_statements")
      .doc(monthKey)
      .delete();
  }

  updateMonthlyStatement(monthKey: string, data: any) {
    return this.db
      .collection("monthly_statements")
      .doc(monthKey)
      .update(data);
  }

  getMonthsList(userUid: string, selectedYear: string) {
    return this.db
      .collection("monthly_statements", ref =>
        ref.where("userUid", "==", userUid).where("year", "==", selectedYear)
      )
      .snapshotChanges();
  }

  getMonthsByUserId(userUid: string) {
    return this.db
      .collection("monthly_statements", ref =>
        ref.where("userUid", "==", userUid)
      )
      .snapshotChanges();
  }
}
