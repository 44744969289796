import { Component, OnInit, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Location } from "@angular/common";

@Component({
  selector: "app-choose-xml-type",
  templateUrl: "./choose-xml-type.component.html",
  styleUrls: ["./choose-xml-type.component.sass"]
})
export class ChooseXmlTypeComponent implements OnInit {
  uid: string;
  monthId: string;
  faArrowLeft = faArrowLeft;
  faTimes = faTimes;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location
  ) {}

  ngOnInit() {
    this.monthId = this.route.snapshot.paramMap.get("month_id");
    this.uid = this.route.snapshot.paramMap.get("uid");
  }

  previousState() {
    this._location.back();
    this._location.back();
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.previousState();
  }

  goToXmlType(xmlType: string) {
    this.router.navigate([
      "/months/" + xmlType + "/" + this.uid + "/" + this.monthId
    ]);
  }
}
