import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  faCheckCircle,
  faClock,
  faPlusCircle,
  faTrashAlt,
  faCheckSquare,
  faSquare,
  faHSquare
} from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "../../core/authentication/auth.service";
import { MonthlyStatementService } from "../../core/services/monthly-statement.service";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
import * as _ from "lodash";

@Component({
  selector: "app-month-list",
  templateUrl: "./month-list.component.html",
  styleUrls: ["./month-list.component.sass"]
})
export class MonthListComponent implements OnInit {
  faCheckCircle = faCheckCircle;
  faClock = faClock;
  faPlusCircle = faPlusCircle;
  faTrashAlt = faTrashAlt;
  faSquare = faSquare;
  faHSquare = faHSquare;
  faCheckSquare = faCheckSquare;
  startYear: any = moment().subtract(8, "year");
  currentYear: any = moment();
  selectedYear: string;
  range = moment.range(this.startYear, this.currentYear);
  years = Array.from(this.range.by("year")).map(m => m.format("YYYY"));
  items: Array<any>;
  currentUserUid: string;

  declarationMode: boolean = false;
  declaring: boolean = false;
  selectedMonths: Array<any> = [];
  selectedId: string;

  emittedLength = 0;
  emittedSubtotal = 0;
  emittedTaxTotal = 0;
  receivedLength = 0;
  receivedSubtotal = 0;
  receivedTaxTotal = 0;
  utility = 0;
  taxToPay = 0;

  constructor(
    public auth: AuthService,
    private monthService: MonthlyStatementService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.selectedYear = this.route.snapshot.paramMap.get("selectedYear");
    this.auth.user.subscribe(user => {
      if (user) this.currentUserUid = user.uid;
      this.getData(this.currentUserUid, this.selectedYear);
    });
  }

  getByYear() {
    this.router.navigate(["/overview/" + this.selectedYear]);
    this.auth.user.subscribe(user => {
      if (user) this.currentUserUid = user.uid;
      this.getData(this.currentUserUid, this.selectedYear);
    });
  }

  getData(userId: string, selectedYear: string) {
    this.monthService.getMonthsList(userId, selectedYear).subscribe(res => {
      this.items = res;
      this.sortByMonth(this.items);
    });
    setTimeout(() => {
      this.calculate();
    }, 1000);
  }

  toggleDeclarationMode(mode: boolean) {
    this.declarationMode = mode;
  }

  calculate() {
    this.emittedLength = 0;
    this.emittedSubtotal = 0;
    this.emittedTaxTotal = 0;
    this.receivedLength = 0;
    this.receivedSubtotal = 0;
    this.receivedTaxTotal = 0;
    this.utility = 0;
    this.taxToPay = 0;
    let monthIndex = _.range(this.items.length);
    _.each(monthIndex, idx => {
      this.emittedLength += parseFloat(
        this.items[idx].payload.doc.data().emittedLength
      );
      this.emittedSubtotal += parseFloat(
        this.items[idx].payload.doc.data().emittedSubtotal
      );
      this.emittedTaxTotal += parseFloat(
        this.items[idx].payload.doc.data().emittedTaxTotal
      );
      this.receivedLength += parseFloat(
        this.items[idx].payload.doc.data().receivedLength
      );
      this.receivedSubtotal += parseFloat(
        this.items[idx].payload.doc.data().receivedSubtotal
      );
      this.receivedTaxTotal += parseFloat(
        this.items[idx].payload.doc.data().receivedTaxTotal
      );
    });
    this.utility = this.emittedSubtotal - this.receivedSubtotal;
    this.taxToPay = this.emittedTaxTotal - this.receivedTaxTotal;
  }

  deleteMonth(monthKey: string) {
    this.monthService
      .deleteMonthlyStatement(monthKey)
      .then(res => {
        this.calculate();
      })
      .catch(err => console.log(err));
  }

  sortByMonth(res) {
    let months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    res.sort(function(a, b) {
      return (
        months.indexOf(a.payload.doc.data().month) -
        months.indexOf(b.payload.doc.data().month)
      );
    });
  }

  selectMonthDeclaration(id: string) {
    if (this.selectedMonths && this.selectedMonths.length > 0) {
      if (this.selectedMonths.includes(id)) {
        let i = this.selectedMonths.indexOf(id);
        this.selectedMonths.splice(i, 1);
      } else {
        this.selectedMonths.push(id);
      }
    } else if (this.selectedMonths.length <= 0) {
      this.selectedMonths.push(id);
    }
  }

  checkIfIncludes(id: string) {
    if (this.selectedMonths.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  declare() {
    this.declaring = true;
  }

  receiveMessage($event: boolean) {
    this.declaring = $event;
    this.declarationMode = $event;
  }

  goToMonth = (monthId: string, event) =>
    this.router.navigate(["/months/" + this.currentUserUid + "/" + monthId]);
}
