import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-declaration-receipt-upload',
  templateUrl: './declaration-receipt-upload.component.html',
  styleUrls: ['./declaration-receipt-upload.component.sass']
})
export class DeclarationReceiptUploadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
